
import WorkshopCards from "../components/moreServices/MoreServices"
import styles from "./SixthScreen.module.css"

const SixthScreen=()=>{


    return <>
    <div className={styles.title} id="סוגי הסדנאות">סוגי הסדנאות</div>
<WorkshopCards/>
    </>
}
export default SixthScreen